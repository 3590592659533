import Papa from "papaparse";
import readExcelFile from "./readExcelFile";

export default function handleDataUpload(
  file,
  data,
  setData,
  setNumDatasets,
  column,
  setColumn
) {
  const validFileTypes = ["xlsx", "csv", "json"];

  // Get file type
  const fileName = file.name;
  const fileExtension = fileName.split(".").pop().toLowerCase();

  if (!validFileTypes.includes(fileExtension)) {
    alert(
      "Invalid file type. Please upload a valid file (CSV, Excel, or JSON)."
    );
    return;
  } else if (fileName in data) {
    alert(
      "A dataset with the same name already exists. Please rename the file."
    );
    return;
  }

  // Handle file upload based on its extension
  if (fileExtension === "csv") {
    handleCSV(file);
  } else if (fileExtension === "xlsx") {
    handleExcel(file);
  } else if (fileExtension === "json") {
    handleJSON(file);
  }
  setNumDatasets((prevNumDatasets) => prevNumDatasets + 1);

  // Handle CSV files
  function handleCSV(file) {
    try {
      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        complete: (results) => {
          setData((prevData) => ({
            ...prevData,
            [fileName]: {
              meta: { fields: results.meta.fields },
              data: results.data,
            },
          }));
          if (column === "") {
            setColumn(results.meta.fields[0]);
          }
        },
        error: (error) => {
          alert("Error processing the CSV file. Please try again.");
          console.error("CSV Parsing Error:", error);
        },
      });
    } catch (error) {
      alert(
        "An unexpected error occurred while processing the CSV file."
      );
      console.error("Error:", error);
    }
  }

  // Handle Excel files
  async function handleExcel(file) {
    try {
      const result = await readExcelFile(file);
      const firstSheetName = Object.keys(result)[0];
      const sheetData = result[firstSheetName];

      const dataColumns = sheetData[0];
      const dataRows = sheetData.slice(1);

      const formattedData = dataRows.map((row) =>
        row.reduce((acc, val, index) => {
          acc[dataColumns[index]] = val;
          return acc;
        }, {})
      );

      setData((prevData) => ({
        ...prevData,
        [fileName]: {
          meta: { fields: dataColumns },
          data: formattedData,
        },
      }));
      if (column === "") {
        setColumn(dataColumns[0]);
      }
    } catch (error) {
      alert("Error processing the Excel file. Please try again.");
      console.error("Excel Processing Error:", error);
    }
  }

  async function handleJSON(file) {
    try {
      const reader = new FileReader();
      reader.onload = () => {
        try {
          const rawContent = reader.result; // Raw content of the file
          console.log("Raw JSON Content:", rawContent);

          // Split content by newlines and parse each line as JSON
          const jsonData = rawContent
            .split("\n")
            .filter((line) => line.trim() !== "") // Remove empty lines
            .map((line) => JSON.parse(line)); // Parse each line

          // Ensure we have an array of objects
          if (
            jsonData.every(
              (item) =>
                typeof item === "object" && !Array.isArray(item)
            )
          ) {
            const fields = Object.keys(jsonData[0] || {});
            setData((prevData) => ({
              ...prevData,
              [file.name]: {
                meta: { fields },
                data: jsonData,
              },
            }));
            if (column === "") {
              setColumn(fields[0]);
            }
          } else {
            alert(
              "Invalid JSON format. Expected an array of objects."
            );
          }
        } catch (error) {
          alert(
            "Error parsing the JSON file. Please check if it's a valid line-delimited JSON."
          );
          console.error("JSON Parsing Error:", error);
        }
      };
      reader.readAsText(file);
    } catch (error) {
      alert(
        "An unexpected error occurred while processing the JSON file."
      );
      console.error("Error:", error);
    }
  }
}
