import {
  Box,
  HStack,
  IconButton,
  Text,
  VStack,
} from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons";
import BasicStatsBox from "./BasicStatsBox";
import ColumnPicker from "./ColumnPicker";
import BarChart from "./BarChart";
import TextLengthDistributionChart from "./TextLengthDistributionChart";

export default function DatasetContainer({
  index,
  fileName,
  fileContent,
  numDatasets,
  column,
  setColumn,
  removeDataset,
}) {
  // filter data based on the selected column
  const filteredData = fileContent.data.map((row) => row[column]);

  // Using Set to find unique topics
  const uniqueTopics = new Set(filteredData);

  // Get the number of unique topics
  const numUniqueTopics = uniqueTopics.size;

  return (
    <Box
      key={`file-${index}`} // Adding a unique key
      justify="center"
      textAlign="center"
      w="100%"
      h="100%"
    >
      <VStack px="8" justify="center" w="100%" spacing="4">
        {/* <HStack w="100%" justify="center">
          <Text>Remove Dataset</Text>
          <IconButton
            borderRadius="full"
            size="sm"
            colorScheme="red"
            aria-label="Remove dataset"
            icon={<CloseIcon />}
            onClick={() => removeDataset(fileName)}
            _hover={{
              bg: "red.100",
              transform: "scale(1.1)",
            }}
            _active={{
              bg: "red.200",
            }}
          />
        </HStack> */}
        <HStack
          w="90%"
          justify="center"
          spacing={4}
          py={3}
          px={4}
          bg="gray.50"
          border="1px solid"
          borderColor="gray.200"
          borderRadius="lg"
          boxShadow="md"
          transition="all 0.3s ease-in-out"
          _hover={{
            bg: "gray.100",
            boxShadow: "lg",
            transform: "scale(1.02)",
          }}
        >
          <Text
            fontWeight="semibold"
            fontSize="lg"
            color="red.500"
            textTransform="uppercase"
            letterSpacing="0.1em"
          >
            Remove Dataset
          </Text>
          <IconButton
            borderRadius="full"
            size="md"
            colorScheme="red"
            aria-label="Remove dataset"
            icon={<CloseIcon />}
            onClick={() => removeDataset(fileName)}
            transition="all 0.2s ease-in-out"
            boxShadow="sm"
            _hover={{
              bg: "red.500",
              color: "white",
              boxShadow: "md",
              transform: "scale(1.15)",
            }}
            _active={{
              bg: "red.600",
              boxShadow: "inner",
            }}
          />
        </HStack>
        <BasicStatsBox
          label="File"
          value={
            <Text>
              {fileName.length > 65 - (numDatasets - 1) * 10
                ? fileName.slice(0, 65 - (numDatasets - 1) * 10) +
                  "..."
                : fileName}
            </Text>
          }
        />

        <BasicStatsBox
          label="Fields"
          value={
            fileContent.meta.fields.length > 3
              ? fileContent.meta.fields.slice(0, 3).join(", ") + "..."
              : fileContent.meta.fields.join(", ")
          }
        />

        <BasicStatsBox label="Rows" value={fileContent.data.length} />
        <ColumnPicker
          data={fileContent}
          column={column}
          setColumn={setColumn}
          freeze={index !== 0}
        />
        <BasicStatsBox
          label="Unique Values"
          value={numUniqueTopics}
        />
        <BarChart
          columnData={filteredData}
          columnName={column}
          uniqueValues={numUniqueTopics}
          index={index}
        />
        <TextLengthDistributionChart
          index={index}
          data={filteredData}
        />
      </VStack>
    </Box>
  );
}
