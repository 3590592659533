import { Box, Text, Select } from "@chakra-ui/react";

export default function ColumnPicker({
  data,
  column,
  setColumn,
  freeze = false,
}) {
  const columns = data?.meta?.fields || []; // Get the fields from meta

  // Check if the current column exists in the available columns
  const isColumnAvailable = columns.includes(column);

  return (
    <Box
      w="90%"
      p={6}
      border="1px solid"
      borderColor="gray.200"
      borderRadius="lg"
      bg="gray.50"
      boxShadow="md"
      transition="all 0.3s ease-in-out"
      _hover={{
        transform: "scale(1.02)",
        boxShadow: "lg",
      }}
    >
      <Text
        fontWeight="semibold"
        fontSize="lg"
        color="gray.500"
        textTransform="uppercase"
        letterSpacing="0.1em"
      >
        {freeze ? "Selected Field" : "Select a Field"}
      </Text>

      <Select
        mt={4}
        value={column} // Ensure the selected column is reflected in the dropdown
        placeholder="Choose a field"
        bg="white"
        borderColor="gray.300"
        borderRadius="md"
        _focus={{
          borderColor: "blue.500",
        }}
        _hover={{
          borderColor: "blue.400",
        }}
        size="lg"
        fontSize="xl"
        boxShadow="sm"
        _focusVisible={{
          boxShadow: "outline",
        }}
        isDisabled={freeze} // Disable the select dropdown if freeze is true
        onChange={(e) => {
          const selectedColumn = e.target.value;
          // Update the setColumn function with the selected column
          setColumn(selectedColumn);
        }}
      >
        {isColumnAvailable ? (
          columns.map((columnOption, index) => (
            <option key={index} value={columnOption}>
              {columnOption}
            </option>
          ))
        ) : (
          <option disabled>No matching Fields</option>
        )}
      </Select>
    </Box>
  );
}
