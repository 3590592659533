import { Box, Text, useBreakpointValue } from "@chakra-ui/react";

export default function BasicStatsBox({ label, value }) {
  const valueColor = useBreakpointValue({
    base: "gray.800", // Default color for smaller screens
    md: "gray.700", // Slightly lighter black for larger screens
  });

  return (
    <Box
      w="90%"
      p={6}
      border="1px solid"
      borderColor="gray.200"
      borderRadius="lg"
      bg="gray.50"
      boxShadow="md"
      transition="all 0.3s ease-in-out"
      _hover={{
        transform: "scale(1.02)",
        boxShadow: "lg",
      }}
    >
      <Text
        fontWeight="semibold"
        fontSize="lg"
        color="gray.500"
        textTransform="uppercase"
        letterSpacing="0.1em"
      >
        {label}
      </Text>
      <Box
        fontSize="md"
        fontWeight="bold"
        color={valueColor}
        mt={2}
        lineHeight="1.2"
      >
        {value}
      </Box>
    </Box>
  );
}
