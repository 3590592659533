import React, { useState } from "react";
import {
  Box,
  Flex,
  Heading,
  HStack,
  IconButton,
  Text,
} from "@chakra-ui/react";
import handleFileSelection from "../Components/handleFileSelection";
import { AddIcon } from "@chakra-ui/icons";
import DatasetContainer from "../Components/DatasetContainer";

export const DatasetComparisonHome = () => {
  const [data, setData] = useState({});
  const [numDatasets, setNumDatasets] = useState(0);
  const [column, setColumn] = useState("");

  const uploadDataset = () => {
    handleFileSelection(
      data,
      setData,
      setNumDatasets,
      column,
      setColumn
    );
  };

  const removeDataset = (filename) => {
    setNumDatasets((prevNumDatasets) => prevNumDatasets - 1);
    setData((prevData) => {
      const newData = { ...prevData };
      delete newData[filename];
      return newData;
    });
  };

  return (
    <Flex direction="column" minH="calc(100vh - 5rem)">
      <Flex w="100%" h="10%" direction="column" align="center">
        <Heading pt="4" as="h1" size="4xl">
          Dataset Comparison
        </Heading>
        <Text fontSize="xl" mt={4}>
          Compare up to 3 datasets side by side to visualize the
          differences.
        </Text>
      </Flex>
      <Box px={8} py={8} w="100%" h="90%">
        {numDatasets === 0 ? (
          <Box
            textAlign="center"
            py={{ base: "10", md: "12" }}
            px={6}
            maxW="lg"
            mx="auto"
            bg="white"
            borderRadius="lg"
            boxShadow="xl"
            transition="all 0.3s ease-in-out"
            _hover={{ boxShadow: "2xl", transform: "scale(1.02)" }}
          >
            <Text
              mb={6}
              fontSize={{ base: "xl", md: "2xl" }}
              fontWeight="bold"
              color="gray.700"
              letterSpacing="wide"
              lineHeight="short"
              textTransform="uppercase"
            >
              Ready to get started?
            </Text>
            <Text
              mb={6}
              fontSize="md"
              color="gray.500"
              maxW="450px"
              mx="auto"
              lineHeight="base"
            >
              Click below to upload your first dataset!
            </Text>
            <IconButton
              colorScheme="purple"
              aria-label="Upload dataset"
              icon={<AddIcon />}
              onClick={uploadDataset}
              borderRadius="full"
              boxShadow="sm"
              transition="all 0.2s ease-in-out"
              _hover={{
                bg: "purple.500",
                boxShadow: "lg",
                transform: "scale(1.05)",
              }}
              _active={{ bg: "purple.600" }}
            />
          </Box>
        ) : (
          <HStack
            w="100%"
            h="100%"
            spacing={4}
            justify="center"
            alignContent="start"
            pt={4}
          >
            {Object.entries(data).map(
              ([fileName, fileContent], index) => (
                <DatasetContainer
                  index={index}
                  fileName={fileName}
                  fileContent={fileContent}
                  numDatasets={numDatasets}
                  column={column}
                  setColumn={setColumn}
                  removeDataset={removeDataset}
                  key={`file-${index}`}
                />
              )
            )}
            {numDatasets < 3 && (
              <Box
                textAlign="center"
                alignSelf="flex-start"
                py={{ base: "10", md: "12" }}
                px={6}
                w={
                  numDatasets === 1
                    ? "80%"
                    : numDatasets === 2
                    ? "50%"
                    : "40%"
                }
                mx="auto"
                bg="white"
                borderRadius="lg"
                boxShadow="xl"
                transition="all 0.3s ease-in-out"
                _hover={{
                  boxShadow: "2xl",
                  transform: "scale(1.02)",
                }}
              >
                <Text
                  mb={6}
                  fontSize={{ base: "xl", md: "2xl" }}
                  fontWeight="bold"
                  color="gray.700"
                  letterSpacing="wide"
                  lineHeight="short"
                  textTransform="uppercase"
                >
                  Want More Data?
                </Text>
                <Text
                  mb={6}
                  fontSize="md"
                  color="gray.500"
                  maxW="450px"
                  mx="auto"
                  lineHeight="base"
                >
                  Click below to add another dataset for comparison.
                </Text>
                <IconButton
                  colorScheme="purple"
                  aria-label="Upload dataset"
                  icon={<AddIcon />}
                  onClick={uploadDataset}
                  borderRadius="full"
                  boxShadow="sm"
                  transition="all 0.2s ease-in-out"
                  _hover={{
                    bg: "purple.500",
                    boxShadow: "lg",
                    transform: "scale(1.05)",
                  }}
                  _active={{ bg: "purple.600" }}
                />
              </Box>
            )}
          </HStack>
        )}
      </Box>
    </Flex>
  );
};
