import { DatasetComparisonHome } from "../Components/DatasetComparison/Pages/DatasetComparisonHome";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";

// 2. Add your color mode config
const config = {
  initialColorMode: "light",
  useSystemColorMode: false,
};

// 3. extend the theme
const theme = extendTheme({ config });

export default function DatasetComparison() {
  return (
    <>
      <ChakraProvider theme={theme}>
        <DatasetComparisonHome />
      </ChakraProvider>
    </>
  );
}
