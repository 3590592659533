import React from "react";
import { Box, Text, VStack } from "@chakra-ui/react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";

// Register Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

export default function TextLengthDistribution({ index, data }) {
  // Ensure data is valid and filter out undefined/null values
  if (
    !Array.isArray(data) ||
    data.length === 0 ||
    !data.some((item) => item)
  ) {
    return (
      <VStack spacing={4} align="stretch" w="90%" h="500px">
        <Box
          p={6}
          border="1px solid"
          borderColor="gray.200"
          borderRadius="lg"
          bg="gray.50"
          boxShadow="md"
          textAlign="center"
        >
          <Text
            fontWeight="semibold"
            fontSize="lg"
            color="gray.500"
            textTransform="uppercase"
            letterSpacing="0.1em"
          >
            No Data Available
          </Text>
        </Box>
      </VStack>
    );
  }

  // Helper function to prepare histogram data based on text lengths
  const prepareHistogramData = (data, bins) => {
    // Extract text lengths while filtering invalid entries
    const textLengths = data
      .filter((item) => item && typeof item.length === "number")
      .map((item) => item.length);

    if (textLengths.length === 0) return Array(bins).fill(0);

    const min = Math.min(...textLengths);
    const max = Math.max(...textLengths);
    const binWidth = (max - min) / bins;
    const histogram = Array(bins).fill(0);

    textLengths.forEach((length) => {
      const bin = Math.floor((length - min) / binWidth);
      histogram[Math.min(bin, bins - 1)] += 1;
    });

    return histogram;
  };

  const histogramData = prepareHistogramData(data, 30);

  const getColor = (index) => {
    switch (index) {
      case 0:
        return { backgroundColor: "#4caf50", borderColor: "#388e3c" };
      case 1:
        return { backgroundColor: "#2196f3", borderColor: "#1976d2" };
      case 2:
        return { backgroundColor: "#f44336", borderColor: "#d32f2f" };
      default:
        return { backgroundColor: "#4caf50", borderColor: "#388e3c" };
    }
  };

  const { backgroundColor, borderColor } = getColor(index);

  const chartData = {
    labels: Array.from({ length: 30 }, (_, i) => (i * 10).toString()),
    datasets: [
      {
        label: "Text Length Distribution",
        data: histogramData,
        backgroundColor,
        borderColor,
        borderWidth: 1,
        fill: true,
        tension: 0.4,
      },
    ],
  };

  return (
    <VStack spacing={4} align="stretch" w="90%" h="500px">
      <Box
        p={6}
        border="1px solid"
        borderColor="gray.200"
        borderRadius="lg"
        bg="gray.50"
        boxShadow="md"
        transition="all 0.3s ease-in-out"
        _hover={{
          transform: "scale(1.02)",
          boxShadow: "lg",
        }}
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        h="100%"
      >
        <Text
          fontWeight="semibold"
          fontSize="lg"
          color="gray.500"
          textTransform="uppercase"
          letterSpacing="0.1em"
        >
          Text Length Distribution
        </Text>
        <Box mt={4} flex="1">
          <Bar
            data={chartData}
            options={{
              responsive: true,
              maintainAspectRatio: false,
              scales: {
                x: {
                  title: { display: true, text: "Text Length" },
                },
                y: {
                  beginAtZero: true,
                  title: { display: true, text: "Frequency" },
                },
              },
              plugins: {
                title: {
                  display: true,
                  text: "Text Length Distribution",
                  font: { size: 18 },
                },
                tooltip: {
                  callbacks: {
                    label: (context) => `${context.raw} occurrences`,
                  },
                },
                legend: { display: false },
              },
            }}
          />
        </Box>
      </Box>
    </VStack>
  );
}
