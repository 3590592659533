import { read, utils } from "xlsx";

export default function readExcelFile(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = (event) => {
      try {
        const data = new Uint8Array(event.target.result);
        const workbook = read(data, { type: "array" });

        // Ensure there are either 3 or 4 tabs
        if (
          workbook.SheetNames.length !== 3 &&
          workbook.SheetNames.length !== 4
        ) {
          throw new Error(
            "The Excel file must contain exactly 3 or 4 tabs."
          );
        }

        // Process each tab
        const sheetsData = {};
        workbook.SheetNames.forEach((sheetName) => {
          const worksheet = workbook.Sheets[sheetName];
          const sheetData = utils.sheet_to_json(worksheet, {
            header: 1,
          });
          sheetsData[sheetName] = sheetData;
        });

        resolve(sheetsData);
      } catch (error) {
        reject(
          new Error(
            `Failed to process the Excel file: ${error.message}`
          )
        );
      }
    };

    reader.onerror = (error) => {
      reject(new Error(`Failed to read the file: ${error.message}`));
    };

    reader.readAsArrayBuffer(file);
  });
}
