import React from "react";
import { Box, Text, VStack } from "@chakra-ui/react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

// Register Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export default function BarChart({
  columnData,
  columnName,
  uniqueValues,
  index,
}) {
  // Validate columnData and ensure it contains valid entries
  if (!Array.isArray(columnData) || columnData.length === 0) {
    return (
      <VStack spacing={4} align="stretch" w="90%" h="500px">
        <Box
          p={6}
          border="1px solid"
          borderColor="gray.200"
          borderRadius="lg"
          bg="gray.50"
          boxShadow="md"
          textAlign="center"
        >
          <Text
            fontWeight="semibold"
            fontSize="lg"
            color="gray.500"
            textTransform="uppercase"
            letterSpacing="0.1em"
          >
            No Data Available
          </Text>
        </Box>
      </VStack>
    );
  }

  // Step 1: Count occurrences of each unique value in the column
  const valueCounts = columnData.reduce((acc, value) => {
    if (value !== null && value !== undefined) {
      acc[value] = (acc[value] || 0) + 1;
    }
    return acc;
  }, {});

  // Step 2: Sort values by count in descending order
  const sortedValues = Object.entries(valueCounts).sort(
    ([, countA], [, countB]) => countB - countA
  );

  // Step 3: Extract the top 15 values and their counts
  const top15Values = sortedValues.slice(0, 15);

  // Handle case where no valid values are found
  if (top15Values.length === 0) {
    return (
      <VStack spacing={4} align="stretch" w="90%" h="500px">
        <Box
          p={6}
          border="1px solid"
          borderColor="gray.200"
          borderRadius="lg"
          bg="gray.50"
          boxShadow="md"
          textAlign="center"
        >
          <Text
            fontWeight="semibold"
            fontSize="lg"
            color="gray.500"
            textTransform="uppercase"
            letterSpacing="0.1em"
          >
            No Valid Data to Display
          </Text>
        </Box>
      </VStack>
    );
  }

  // Step 4: Prepare data for the chart
  const labels = top15Values.map(([value]) => {
    if (!value || typeof value !== "string") {
      return "Null"; // Fallback for empty/malformed values
    }
    return value.length > 20 ? `${value.slice(0, 20)}...` : value;
  });

  // Extract counts from the top 15 values
  const counts = top15Values.map(([, count]) => count);

  // Updated Step 5: Normalize the counts based on the entire dataset
  const totalDataCount = Object.values(valueCounts).reduce(
    (sum, count) => sum + count,
    0
  );
  const normalizedCounts = counts.map(
    (count) => count / totalDataCount
  );

  // Step 6: Set colors based on the index
  const getColor = (index) => {
    switch (index) {
      case 0:
        return {
          backgroundColor: "#4caf50", // Green
          borderColor: "#388e3c", // Darker Green
        };
      case 1:
        return {
          backgroundColor: "#2196f3", // Blue
          borderColor: "#1976d2", // Darker Blue
        };
      case 2:
        return {
          backgroundColor: "#f44336", // Red
          borderColor: "#d32f2f", // Darker Red
        };
      default:
        return {
          backgroundColor: "#4caf50", // Default Green
          borderColor: "#388e3c", // Default Darker Green
        };
    }
  };

  const { backgroundColor, borderColor } = getColor(index);

  const chartData = {
    labels,
    datasets: [
      {
        label: `${columnName} Count (Normalized)`,
        data: normalizedCounts, // Use normalized counts
        backgroundColor: Array(normalizedCounts.length).fill(
          backgroundColor
        ),
        borderColor: Array(normalizedCounts.length).fill(borderColor),
        borderWidth: 1,
      },
    ],
  };

  return (
    <VStack spacing={4} align="stretch" w="90%" h="500px">
      <Box
        p={6}
        border="1px solid"
        borderColor="gray.200"
        borderRadius="lg"
        bg="gray.50"
        boxShadow="md"
        transition="all 0.3s ease-in-out"
        _hover={{
          transform: "scale(1.02)",
          boxShadow: "lg",
        }}
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        h="100%" // Ensure box takes full height
      >
        <Text
          fontWeight="semibold"
          fontSize="lg"
          color="gray.500"
          textTransform="uppercase"
          letterSpacing="0.1em"
        >
          Top {uniqueValues < 15 ? uniqueValues : 15}{" "}
          {columnName.charAt(0).toUpperCase() + columnName.slice(1)}s
        </Text>
        <Box mt={4} flex="1">
          <Bar
            data={chartData}
            options={{
              responsive: true,
              maintainAspectRatio: false,
              scales: {
                y: {
                  beginAtZero: true,
                  ticks: {
                    callback: (value) =>
                      `${(value * 100).toFixed(0)}%`,
                  },
                },
              },
              plugins: {
                title: {
                  display: true,
                  text: `${
                    columnName.charAt(0).toUpperCase() +
                    columnName.slice(1)
                  } Count (Normalized)`,
                  font: { size: 18 },
                },
                tooltip: {
                  callbacks: {
                    label: (context) =>
                      `${(context.raw * 100).toFixed(
                        2
                      )}% occurrences`,
                  },
                },
              },
            }}
          />
        </Box>
      </Box>
    </VStack>
  );
}
