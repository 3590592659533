import handleDataUpload from "./handleDataUpload";

const handleFileSelection = async (
  data,
  setData,
  setNumDatasets,
  column,
  setColumn
) => {
  try {
    const fileHandle = await window.showOpenFilePicker({
      types: [
        {
          description: "Dataset Files",
          accept: {
            "application/json": [".json"],
            // "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
            //   [".xlsx"],
            "text/csv": [".csv"],
          },
        },
      ],
      multiple: false,
    });
    const file = await fileHandle[0].getFile();
    handleDataUpload(
      file,
      data,
      setData,
      setNumDatasets,
      column,
      setColumn
    );
  } catch (error) {
    if (error.name !== "AbortError") {
      alert("File selection canceled or failed.");
      console.error("File Selection Error:", error);
    }
  }
};

export default handleFileSelection;
